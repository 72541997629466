<template>
 <div class="uploadBox">
   <ul>
     <li v-for="(item, index) in files" :class="(index + 1) % 3 === 0 ? 'li_right' : ''">
       <div class="delBtn" @click="delFile(item.relativePath)" v-show="delBtnShow"><van-icon name="cross" size=".3rem"/></div>
<!--       图片类型-->
       <van-image
           v-if="item.fileType =='image' || item.fileType == 1"
           fit="contain"
           width="100%"
           height="100%"
           @click="imgPreView(item.relativePath)"
           :src="getFilePath(item.relativePath)"
       />

       <!--       视频类型-->
       <video :class="'video' + index" :key="item.relativePath" :id="item.relativePath"
              v-if="item.fileType =='video' || item.fileType == 2" class="uploadVideo"
              :src="getFilePath(item.relativePath)"/>
       <div :class="'videoPlay' + index" @click="videoPlay(index)" v-if="item.fileType =='video' || item.fileType == 2"
            class="videoPlay"><van-icon name="play" color="#ccc" size="1rem"/></div>

       <!--       音频类型-->
       <audio :class="'audio' + index" :key="item.relativePath" :id="item.relativePath"
              v-if="item.fileType =='audio' || item.fileType == 3" class="uploadAudio"
              @ended="audioEND(index)"
              :src="getFilePath(item.relativePath)"/>
       <div :class="'audioPlay' + index" @click="audioPlay(index)" v-if="item.fileType =='audio' || item.fileType == 3"
            class="audioPlay"><van-icon name="play" color="#ccc" size="1rem"/></div>
       <div :class="'audioPause' + index" @click="audioPause(index)" v-if="item.fileType =='audio' || item.fileType == 3"
             style="display: none" class="audioPause"><van-icon name="pause" color="#ccc" size="1rem"/></div>

       <!--       文件类型-->
       <van-image
           v-if="item.fileType !=='image' && item.fileType !== 1 && item.fileType !=='video' && item.fileType !== 2"
           fit="contain"
           width="100%"
           height="100%"
           @click="downloadFile(item)"
           :src="getFilePath(`files/wx/images/content/${item.fileType === 3 ? 'audio' : item.fileType}.png`)"
       />
     </li>
     <li v-show="uploadBtnShow">
       <van-uploader preview-size="2.4rem"  :accept="accept" :preview-image="false" multiple  :after-read="uploadRead"  :before-read="beforeUpload"/>
     </li>
   </ul>
 </div>
</template>

<script>
import {beforeFileUpload} from "@/utils/utils";
import {getImageStream} from "@/utils";
import { ImagePreview } from 'vant';
export default {
name: "uploadFile",
  props: {
    // 默认文件列表
    //   必需包含  relativePath  为file开头路径
    fileList: {
      type: Array,
      required: true
    },
    //文件上传的路径
    uploadPath: {
      type: String,
      required: true
    },
  //  可上传的文件类型 默认全部
  //  可选   all 全部
    //      imageOrVideo 图片加视频
    //      image    图片
    //      imageOrAudio 图片加音频
    fileType: {
      type: String,
      default: 'all'
    },
  //  显示删除按钮  默认显示
    delBtnShow: {
      type: Boolean,
      default: true
    },
  //  显示上传按钮 默认显示
    uploadBtnShow: {
      type: Boolean,
      default: true
    },
    //  文件跟新时携带回的参数
    query: {
      default: '',
    }
  },
  watch: {
    fileList: {
      deep: true,
      handler (val) {
        this.files = this.fileList
      }
    }
  },
  data () {
   return {
     files: [],
     accept: '*',
     audioStatus: false
   }
  },
  methods: {
    getFilePath (path) {
    return getImageStream(path)
  },
    //大图展示
    imgPreView (path) {
    let index
    let list = this.files.map((item, i) => {
      if (item.fileType == 'image' || item.fileType == 1) {
        return this.getFilePath(item.relativePath)
      }
    })
      this.files.forEach((item, i) => {
        if (item.relativePath == path) {
          index = i
        }
      })
      ImagePreview({
        images: list,
        startPosition: index,
        closeable: true,
      });
    },
    //视频播放
    videoPlay (index) {
      let myVideo = document.getElementsByClassName('video' + index)[0]
      let myVideoPlay = document.getElementsByClassName('videoPlay' + index)[0]
      myVideoPlay.style.display = 'none'
      this.launchFullscreen(myVideo)
      myVideo.play()
      myVideoPlay.style.display = 'flex'
    },
    //音频播放
    audioPlay (index) {
      let audioList = document.getElementsByClassName('uploadAudio')
      let myAudioPlayList = document.getElementsByClassName('audioPlay')
      let myAudioPauseList = document.getElementsByClassName('audioPause')
      for (let i = 0; i < audioList.length; i++) {
        audioList[i].pause()
        audioList[i].currentTime = 0
        myAudioPlayList[i].style.display = 'flex'
        myAudioPauseList[i].style.display = 'none'
      }
      let myAudio = document.getElementsByClassName('audio' + index)[0]
      let myAudioPlay = document.getElementsByClassName('audioPlay' + index)[0]
      let myAudioPause = document.getElementsByClassName('audioPause' + index)[0]
      myAudioPlay.style.display = 'none'
      myAudioPause.style.display = 'flex'
      myAudio.play()
      this.audioStatus = true
    },
    //音频播放结束
    audioEND (index) {
      let myAudio = document.getElementsByClassName('audio' + index)[0]
      let myAudioPlay = document.getElementsByClassName('audioPlay' + index)[0]
      let myAudioPause = document.getElementsByClassName('audioPause' + index)[0]
      myAudioPlay.style.display = 'flex'
      myAudioPause.style.display = 'none'
      myAudio.currentTime = 0
      this.audioStatus = false
    },
    //音频暂停
    audioPause (index) {
      let myAudio = document.getElementsByClassName('audio' + index)[0]
      let myAudioPlay = document.getElementsByClassName('audioPlay' + index)[0]
      let myAudioPause = document.getElementsByClassName('audioPause' + index)[0]
      myAudioPlay.style.display = 'flex'
      myAudioPause.style.display = 'none'
      myAudio.pause()
    },
    //進入全屏
    launchFullscreen(element)
    {
      //此方法不可以在異步任務中執行，否則火狐無法全屏
      if(element.requestFullscreen) {
        element.requestFullscreen();
      } else if(element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if(element.msRequestFullscreen){
        element.msRequestFullscreen();
      } else if(element.oRequestFullscreen){
        element.oRequestFullscreen();
      }
      else if(element.webkitRequestFullscreen)
      {
        element.webkitRequestFullScreen();
      }else{

        var docHtml  = document.documentElement;
        var docBody  = document.body;
        var videobox  = document.getElementById('videobox');
        var  cssText = 'width:100%;height:100%;overflow:hidden;';
        docHtml.style.cssText = cssText;
        docBody.style.cssText = cssText;
        videobox.style.cssText = cssText+';'+'margin:0px;padding:0px;';
        document.IsFullScreen = true;

      }
    },
    //退出全屏
    exitFullscreen()
    {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.oRequestFullscreen){
        document.oCancelFullScreen();
      }else if (document.webkitExitFullscreen){
        document.webkitExitFullscreen();
      }else{
        var docHtml  = document.documentElement;
        var docBody  = document.body;
        var videobox  = document.getElementById('videobox');
        docHtml.style.cssText = "";
        docBody.style.cssText = "";
        videobox.style.cssText = "";
        document.IsFullScreen = false;
      }
    },
    // 下载文件
    downloadFile (file) {
      const link = document.createElement('a');
      link.href = this.getFilePath(file.relativePath);
      link.download = file.oldFileName; //下载
      link.click();//进行点击时下载
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传文件
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", this.uploadPath)
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            if (!fileMessage.suffix) {
              fileMessage['suffix'] =fileMessage['fileType']
            }
            this.files.push(fileMessage)
            this.filesUpload()
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    //删除文件
    delFile (path) {
      let id
      this.files = this.files.filter(item => {
        if (item.relativePath == path && item.id) {
          id = item.id
        }
        return item.relativePath != path
      })
      this.filesUpload(id, true)
    },
  //  通知外层文件列表更改
    filesUpload (id, isDel) {
      if (isDel && id) {
        this.$emit('delFile', id, this.query)
      }
      console.log(this.files)
      this.$emit('filesUpload', this.files, this.query)
    },
    //设置可上传文件类型
    setFileType () {
      switch (this.fileType) {
        case 'all':
          this.accept = '*'
          break
        case 'image':
          this.accept = 'image/*'
          break
        case 'imageOrVideo':
          this.accept = 'image/*, video/*'
          break
        case 'imageOrAudio':
          this.accept = 'image/*, audio/*'
          break
        case 'imageOrVideoOrAudio':
          this.accept = 'image/*, video/*, audio/*'
      }
    }
  },
  created() {
    this.files = this.fileList
    this.setFileType()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-uploader__upload{
  margin: 0.1rem 0 0.1rem 0.1rem;
}
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.uploadBox {
  overflow: hidden;
  margin: 15px 0 15px 0;
  ul {
    .li_right {
      margin-right: 0;
    }
    li {
      float: left;
      width: 200px;
      height: 200px;
      margin-right: 18px;
      margin-bottom: 15px;
      padding: 2px;
      position: relative;
      overflow: hidden;
      border: 1px solid #ccc;
      border-radius: 10px;
      .delBtn {
        z-index: 999;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-color: #ccc;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .uploadImg {
        width: 196px;
      }
      .uploadVideo {
        width: 196px;
        height: 196px;
      }
      .videoPlay, .audioPlay, .audioPause {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0A1121;
        opacity: .5;
      }
    }
  }
}
</style>
