<template>
  <div class="info">
    <top-bar :title="'活动详情'" :left="true"></top-bar>

    <van-popup v-model="typeShow" position="bottom" style="max-height: 50%;">
      <div class="typeCheck">
        <span @click="typeShow = false">取消</span>
        <span>请选择</span>
        <span @click="typeConfirm">确定</span>
      </div>
      <van-checkbox-group class="typeCheckBox" @change="change" v-model="checkedValue">
        <van-cell-group v-for="(item, index) in typeList" :key="index">
          <van-cell  :title="item.label"  @click="toggle(index)">
            <template #icon>
              <van-checkbox :name="item" ref="checkboxes"/><van-icon v-if="item.children !== null" :name="childrenVisible ?'arrow-up':'arrow-down'" @click.stop="childrenVisible = !childrenVisible"/>
            </template>
          </van-cell>
          <van-checkbox-group v-if="childrenVisible" class="children" @change="childrenChange" v-model="childrenValue">
            <van-cell-group v-for="(items, i) in item.children" :key="i">
              <van-cell  :title="items.label"  @click="toggleChildren(i)">
                <template #icon>
                  <van-checkbox :name="items" ref="childrenBoxes"/>
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </van-cell-group>
      </van-checkbox-group>
      <!--      <van-checkbox-group ref="checkboxGroup" @change="change" v-model="checkedValue">-->
      <!--        <van-cell-group>-->
      <!--          <van-cell v-for="(item, index) in typeList" :key="index" :title="item.label" @click="toggle(index)">-->
      <!--            <template #right-icon>-->
      <!--              <van-checkbox :name ="item" ref="checkboxes"/>-->
      <!--            </template>-->
      <!--          </van-cell>-->
      <!--        </van-cell-group>-->
      <!--      </van-checkbox-group>-->
      <!--      <van-picker title="标题" value-key="label" show-toolbar :columns="typeList" @confirm="typeConfirm" @cancel="typeShow = !typeShow" />-->
    </van-popup>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="datetime" title="选择年月日" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="datetime" title="选择年月日" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="surveyObjectShow" position="bottom"  >
      <van-picker title="面向对象" value-key="label" show-toolbar :columns="surveyObjectOptions" @confirm="surveyObjectConfirm" @cancel="surveyObjectShow = !surveyObjectShow" />
    </van-popup>
    <van-popup v-model="communityShow" position="bottom"  >
      <van-picker title="社区" cancel-button-text="重置" value-key="label" show-toolbar :columns="communityList" @confirm="communityConfim" @cancel="communityShow = !communityShow" />
    </van-popup>
    <!-- 列表 -->
    <van-cell>
      <template #title>
        <div class="blue-box"></div>
        <span class="custom-title">基本信息</span>
      </template>
    </van-cell>
<!--    <van-cell title="所属机构" :value="dataForm.orgName || '请选择'"  @click="communityShow = changeOrDetail"-->
<!--              :value-class="{'value-common':!dataForm.orgName}" :is-link="changeOrDetail" />-->
    <van-field clearable clear-trigger="always" v-model="dataForm.title" label="活动名称" :readonly="!changeOrDetail" placeholder="请输入" input-align="right"/>
    <van-field clearable clear-trigger="always" v-model="dataForm.address" label="活动地点" :readonly="!changeOrDetail" placeholder="请输入" input-align="right"/>
    <van-cell title="开始时间" :value="dataForm.beginTime || '请选择'"  @click="beginDateShow = changeOrDetail"
              :value-class="{'value-common':!dataForm.beginTime}" :is-link="changeOrDetail" />
    <van-cell title="结束时间" :value="dataForm.endTime || '请选择'"   @click="endDateShow = changeOrDetail"
              :value-class="{'value-common':!dataForm.endTime}" :is-link="changeOrDetail" />
    <van-field clearable clear-trigger="always" v-model="dataForm.joinNum" label="参与人数" :readonly="!changeOrDetail" placeholder="请输入" input-align="right"/>
    <van-field clearable clear-trigger="always" v-model="dataForm.speaker" label="主讲人" :readonly="!changeOrDetail" placeholder="请输入" input-align="right"/>
    <van-cell title="活动类型" :value="dataForm.typeStr || '请选择'"  @click="typeShow = changeOrDetail"
              :value-class="{'value-common':!dataForm.type}" :is-link="changeOrDetail" />
    <van-cell title="面向对象" is-link  :value="dataForm.surveyObjectStr || '居民'"  @click="surveyObjectShow = changeOrDetail"/>
    <van-field clearable clear-trigger="always" v-model="dataForm.content" rows="3" class="left"
               :border="false" :readonly="!changeOrDetail" type="textarea" input-align="right" maxlength="50" placeholder="请输入活动详情"/>
    <van-row>
      <van-col :span="24">
<!--        <van-uploader v-model="newFileList" :deletable="changeOrDetail" :show-upload="changeOrDetail" multiple @delete="uploaddel" :after-read="uploadRead"/>-->
        <div class="upload">
          <upload-file
              :file-list="newFileList"
              :del-btn-show="changeOrDetail"
              :uploadPath="uploadPath"
              @filesUpload="filesUpload"
              @delFile="delFile"
              :upload-btn-show="changeOrDetail"
              :file-type="'imageOrVideo'"/>
        </div>
      </van-col>
    </van-row>
    <!--    </van-cell-group>-->

    <van-row v-if="changeOrDetail && (dataForm.orgId == orgid)" class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" round @click="submit">完成</van-button>
      </van-col>
    </van-row>
    <van-row class="btns" gutter="10" v-else-if="dataForm.orgId == orgid">
      <van-col :span="12">
        <van-button plain type="danger" size="large" round @click="delt" style="background-color:transparent">删除</van-button>
      </van-col>
      <van-col :span="12">
        <van-button type="info" size="large" round @click="changeOrDetail = !changeOrDetail">编辑</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDateTimeWithOutSec} from '@/utils/utils'
import { mapState } from 'vuex'
import {getImageStream} from '@/utils/index'
import {getVirtualDict, getBelongCommunity} from "@/utils/common";
import uploadFile from '@/components/upload/uploadFile'

export default {
  name: 'activity-info',
  components :{
    topBar,
    uploadFile
  },
  computed:{...mapState(['activityId'])},
  data () {
    return {
      firstValue: [],
      secondValue: [],
      uploadPath: 'files/activity/temporary',
      changeOrDetail: false,
      beginDateShow : false,
      endDateShow : false,
      typeShow : false,
      isTasks: false,
      childrenVisible: false,
      surveyObjectShow: false,
      communityShow: false,
      typeStr: '',
      surveyObjectStr: '居民',
      communityStr: '',
      typeList: [],
      fileList: [],
      surveyObjectOptions: [],
      newFileList: [],
      deleteFiles: [],
      checkedValue: [],
      childrenValue: [],
      communityList: [],
      orgid: 0,
      dataForm: {
        id: '',//活动id
        title: '',  //活动名称
        address: '', //活动地址
        beginTime: '', //开始时间
        endTime: '', //结束时间
        joinNum: '', //参与人数
        typeOptions: [],//活动类型选择器下拉列表
        type: '', //活动类型
        content: '', //活动描述
        speaker: '',  //主讲人
        fileList: [],
        surveyObject: '1',  //默认面向居民
        orgId: 0, //所属机构
      },
    }
  },
  methods : {
    filesUpload (files) {
      this.fileList = files
    },
    delFile (id) {
      this.deleteFiles.push(id)
    },
    getInfo () {
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.isTasks === true? this.dataForm.id : this.activityId,
          orgId: this.$globalData.userInfo.orgId,
          orgUserId: this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        console.log(data);
        if (data.code == 0) {
          if (data.entityVO.fileList && data.entityVO.fileList.length != 0) {
            data.entityVO.fileList.forEach(item=> {
              let u;
              if (item.fileType == 1 || item.fileType == 2) {
                u = getImageStream(item.filePath)
                let url = {
                  uid: this.dataForm.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.fileType,
                  relativePath: item.filePath,
                  url: u,
                  realUrl: item.filePath.replace(/\\/g, "/")
                }
                this.dataForm.fileList.push(url);
                this.newFileList.push(url);
              }
            })
          }
          this.dataForm = data.entityVO
          this.dataForm.orgId = data.entityVO.orgId
          let type = this.dataForm.type.split(',').map((n) => Number(n))
          this.typeList.map(item => {
            type.map(val => {
              if (item.value == val) {
                this.checkedValue.push(item)
              }
              if (item.children !== null) {
                item.children.map(e => {
                  if (e.value == val) {
                    this.childrenValue.push(e)
                  }
                })
              }
            })
          })
          this.dataForm.id = this.activityId
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getTypeOptions () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'activityType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.typeList = data.dicts
        }
      },err=> {this.$toast.error({message:'活动类型获取失败'})})
    },
    /**
     * 获取活动面向对象
     */
    getActivitySurveyObjectOptions () {
      getVirtualDict('activitySurveyObject', (dicts) => {
        this.surveyObjectOptions = dicts
      })
    },
    submit () {
      let parentCheck = []
      let childrenCheck = []
      this.checkedValue.map(item => {
        parentCheck.push(item.value)
      })
      this.childrenValue.map(item => {
        childrenCheck.push(item.value)
      })
      this.dataForm.type = parentCheck.concat(childrenCheck)
      this.dataForm.type = this.dataForm.type.join(',')
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/update'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          'newFileMessages': this.fileList,
          'deleteFiles': this.deleteFiles
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('修改成功')
          this.$router.go(-1)
        }
      },err=> {this.$toast.error({message:'修改失败'})})
    },
    // 删除数据
    delt () {
      this.$dialog.confirm({
        title: '删除',
        message: '确认删除吗',
      })
          .then(() => {
            this.$http({
              url: this.$http.adornUrl('/wxapp/activity/delete'),
              method: 'post',
              params: this.$http.adornParams({
                ids:this.activityId
              })
            }).then(({data})=> {
              if (data.code == 0) {
                localStorage.setItem('del', 1)
                this.$toast.success({message:'删除成功'})
                setTimeout(() => {
                  this.$router.go(-1)
                }, 500)
              }
            },err=> {this.$toast.error({message:'删除失败'})})
          })
          .catch(() => {
          });
    },
    // 删除图片
    uploaddel (file, detail) {
      this.$dialog.confirm({
        title: '删除',
        message: '确认删除图片吗',
      }).then(()=> {
        let i = detail.index
        this.dataForm.fileList.splice(i, 1)
        this.deleteFiles.push(file.id)
      })
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", 'files/activity/temporary');
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let uid = this.dataForm.fileList.length != 0 ? this.dataForm.fileList[this.dataForm.fileList.length - 1].uid + 1 : 0
            data.fileMessage['uid'] = uid
            this.dataForm.fileList.push(data.fileMessage)
            this.fileList.push(data.fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    typeConfirm () {
      this.typeShow = false
    },
    change(val) {
      console.log(this.checkedValue)
      this.firstValue = val
      this.dataForm.typeStr = val.map(function(item,index){
        return item.label}).concat(this.secondValue.map(item => {
        return item.label
      })).join(',')
      // val.map(e => {
      //   this.dataForm.type.push(e.value)
      // })
      this.typeShow = true
    },
    childrenChange (val){
      this.secondValue = val
      this.dataForm.typeStr = val.map(function(item,index){
        return item.label}).concat(this.firstValue.map(item => {
        return item.label
      })).join(',')
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    toggleChildren(index) {
      this.$refs.childrenBoxes[index].toggle();
    },
    surveyObjectConfirm (value) {
      this.surveyObjectStr = value.label
      this.dataForm.surveyObject = value.value
      this.surveyObjectShow = false
    },
    beginDateConfim (value) {
      let d = new Date(this.dataForm.endTime)
      if (this.dataForm.endTime != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.dataForm.beginTime = formatterDateTimeWithOutSec(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.dataForm.beginTime)
      if (this.dataForm.beginTime != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.dataForm.endTime = formatterDateTimeWithOutSec(value)
      this.endDateShow = false
    },
    // 选择所属机构
    communityConfim(value){
      this.dataForm.orgName = value.label
      this.dataForm.orgId = value.value
      this.communityShow = false
    }
  },
  created () {
    if (this.$route.query.tasks) {
      this.dataForm.id = this.$route.query.id
      this.isTasks = true
    }
    this.orgid = Number(this.$orgId)
    getBelongCommunity(this.$orgId, -1, (communityList) => {
      this.communityList = communityList
    })
    this.isDel = 0
    this.getTypeOptions()
    this.getActivitySurveyObjectOptions()
    this.getInfo()
  }
}
</script>
<style lang="scss" scoped>
.typeCheck {
  display: flex;
  height: 150px;
  line-height: 115px;
  padding-left: 40px;
  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
  }
}
.typeCheckBox .van-cell__title {
  margin-left: 20px;
}
.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}
.children {
  margin-left: 50px;
}
.children .van-cell__value {
  margin-left: 10px;
}
</style>
